<template>
  <Table :row-class-name="rowClassName" :columns="columns1" :data="data1" size="small"/>
</template>

<script>
  export default {
    name: "trainingSituationTable",
    data() {
      return {
        columns1: [
          {
            title: '机构名称',
            key: 'name',
            align: 'center',
          },
          {
            title: '当前教师',
            key: 'dqjss',
            align: 'center',
          },
          {
            title: '当前学员',
            key: 'dqxys',
            align: 'center',
          },
          {
            title: '招生人数',
            key: 'ljzsrs',
            align: 'center',
          },
          {
            title: '开班数量',
            key: 'ljkbsl',
            align: 'center',
          },
          {
            title: '报考人数',
            key: 'ljbkrs',
            align: 'center',
          },
        ],

        data1: [
          {
            name: '机构1',
            dqjss: '52',
            dqxys: '51',
            ljzsrs: '233',
            ljkbsl: '455',
            ljbkrs: '44'
          },
          {
            name: '机构2',
            dqjss: '52',
            dqxys: '51',
            ljzsrs: '233',
            ljkbsl: '455',
            ljbkrs: '44'
          },

          {
            name: '机构3',
            dqjss: '52',
            dqxys: '51',
            ljzsrs: '233',
            ljkbsl: '455',
            ljbkrs: '44'
          },

          {
            name: '机构4',
            dqjss: '52',
            dqxys: '51',
            ljzsrs: '233',
            ljkbsl: '455',
            ljbkrs: '44'
          },

        ]
      }
    },
    methods: {
      rowClassName(row, index) {
        if (index >= 0) {
          return 'demo-table-info-row';
        }
        return '';
      }
    }
  }
</script>

<style>
  .ivu-table {
    background: none;
    font-size: 14px;
  }

  .ivu-table:before {
    background: none;
  }

  .ivu-table th {
    background-color: rgba(255, 255, 255, 0);
    color: white;
    border: none;
  }

  .ivu-table .demo-table-info-row td {
    background: none;
    color: white;
    border: none;
  }


</style>
