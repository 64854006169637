<template>
  <div class="head">
    <!--  标题  65-->
    <div class="bt">
      <p class="bt1 media-scale">安康市智慧康养云服务平台指挥监控大屏</p>
    </div>
    <div class="middle">
      <div class="m-left">
        <div style=" height: calc((100% - 20px) * 0.31);position: relative">
          <!--    老人结构信息
              -->
          <div class="title">老人结构信息

            <div class="right-top-btn" style="right: 68px;" :title="'年龄结构信息'"
              :class="{'active-btn-bgcolor': laoRenJieGouXinXiFlag === 0}"
              @click="changeLaoRenJieGouXinXiFlag(0)"
            >{{'龄'}}</div>

            <div class="right-top-btn" style="right: 39px;" :title="'能力结构信息'"
              :class="{'active-btn-bgcolor': laoRenJieGouXinXiFlag === 1}"
                 @click="changeLaoRenJieGouXinXiFlag(1)"
            >{{'能'}}</div>
            <div class="right-top-btn" style="right: 10px;" :title="'居家结构信息'"
              :class="{'active-btn-bgcolor': laoRenJieGouXinXiFlag === 2}"
                 @click="changeLaoRenJieGouXinXiFlag(2)"
            >{{'居'}}</div>
          </div>
          <border style="width: 100%;height: calc(100% - 40px);"/>
          <div class="echart-wrap">
            <orderManStructure  v-if="laoRenJieGouXinXiFlag === 0"></orderManStructure>
            <orderManStructure2 v-if="laoRenJieGouXinXiFlag === 1"></orderManStructure2>
            <orderManStructure3 v-if="laoRenJieGouXinXiFlag === 2"></orderManStructure3>
          </div>
        </div>
        <div style=" height: calc((100% - 20px) * 0.32);position: relative;margin-top: 10px">
          <!--   服务人员结构信息    -->
          <div class="title">服务人员结构信息
            <div class="right-top-btn right-top-btn-big" style="right: 63px;" :title="'人社分类'"
                 :class="{'active-btn-bgcolor': fuWuRenYuanJieGouFlag === 0}"
                 @click="changeFuWuRenYuanJieGouFlag(0)"
            >{{'人社'}}</div>
            <div class="right-top-btn right-top-btn-big" style="right: 10px;" :title="'机构服务'"
                 :class="{'active-btn-bgcolor': fuWuRenYuanJieGouFlag === 1}"
                 @click="changeFuWuRenYuanJieGouFlag(1)"
            >{{'机构'}}</div>
          </div>
          <border style="width: 100%;height: calc(100% - 40px);"/>
          <div class="echart-wrap">
<!--            <fwryZZT v-if="fuWuRenYuanJieGouFlag === 0"/>-->
            <fwryZZT v-if="fuWuRenYuanJieGouFlag === 0"/>
            <fwryZZT2 v-if="fuWuRenYuanJieGouFlag === 1"/>
          </div>
        </div>
        <div style=" height: calc((100% - 20px) * 0.37);margin-top: 10px;position: relative">
          <!--    服务对象绑定服务机构    -->
          <div class="title">区县服务结构信息

<!--            <div class="right-top-btn" style="right: 39px;" :title="'能力结构信息'"-->
<!--                 :class="{'active-btn-bgcolor': quXianFlag === 0}"-->
<!--                 @click="changeQuXianFlag(0)"-->
<!--            >{{'区'}}</div>-->
<!--            <div class="right-top-btn" style="right: 10px;" :title="'居家结构信息'"-->
<!--                 :class="{'active-btn-bgcolor': quXianFlag === 1}"-->
<!--                 @click="changeQuXianFlag(1)"-->
<!--            >{{'县'}}</div>-->
          </div>
          <border style="width: 100%;height: calc(100% - 40px);"/>
          <div class="echart-wrap">
            <!--表  开始-->
            <div style="text-align: center;width: calc(100% - 10px);height: calc(100% - 10px);overflow-y: hidden;" class="media-font">
              <div style="width: 100%;display: flex;color: #fff;justify-content: space-around">
                <div style="width: 14.28%;" class="small-table-cell" >区县</div>
                <div style="width: 14.28%;" class="small-table-cell" >养老院</div>
                <div style="width: 14.28%;" class="small-table-cell" >日间照料</div>
                <div style="width: 14.28%;" class="small-table-cell" >驿站</div>
                <div style="width: 14.28%;" class="small-table-cell" >护理站</div>
                <div style="width: 14.28%;" class="small-table-cell" >网格员</div>
                <div style="width: 14.28%;" class="small-table-cell" >绑定人数</div>

              </div>
              <div style="height: calc(100% - 36px);overflow-y: hidden;" id="mainTable">
                <div :class="{ 'animate-up-small-table': animateUp}" style="width: 100%;display: flex;color: #fff;justify-content: space-around;" v-for="(item, index) in data1" :key="index">
                  <div style="width: 14.28%;" class="small-table-cell">{{item.area}}</div>
                  <div style="width: 14.28%;" class="small-table-cell">{{item.yly}}</div>
                  <div style="width: 14.28%;" class="small-table-cell">{{item.rjzl}}</div>
                  <div style="width: 14.28%;" class="small-table-cell">{{item.yizhan}}</div>
                  <div style="width: 14.28%;" class="small-table-cell">{{item.hlz}}</div>
                  <div style="width: 14.28%;" class="small-table-cell">{{item.wgy}}</div>
                  <div style="width: 14.28%;" class="small-table-cell">{{item.bdrs}}</div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-middle">
        <!--        中间图-->
        <div style="" class="org">
          <map_baidu style="height: 100%"/>
        </div>
      </div>
      <div class="m-right">
        <div style=" height: calc((100% - 20px) * 0.31);position: relative">
          <!--    招生,报考,结业趋势    -->
          <div class="title">政府信息公示窗口</div>
          <border style="width: 100%;height: calc(100% - 40px);    position: absolute;"/>
          <div style="height: calc(100% - 50px);position: relative;z-index: 100;overflow: hidden;padding-top: 18px;width: 100%">
            <div class="list-child media-font"
                 v-for="(item, index) in yuanData" :key="index">
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
        <div style=" height: calc((100% - 20px) * 0.31);position: relative;margin-top: 10px">
          <!--   教师学员学历分布    -->
          <div class="title">服务项目收费信息</div>
          <border style="width: 100%;height: calc(100% - 40px);"/>
          <div class="echart-wrap">

            <div class="media-font" style="text-align: center;font-size: 14px;width: calc(100% - 10px);height: calc(100% - 10px);overflow-y: hidden;" >
              <div style="width: 100%;height: 12%;display: flex;color: #fff;justify-content: space-around">
                <div style="width: 33.3%;" class="small-table-cell" >项目</div>
                <div style="width: 33.3%;" class="small-table-cell" >机构评估费/次</div>
                <div style="width: 33.3%;" class="small-table-cell" >居家评估费/次</div>

              </div>
              <div style="display: flex;flex-direction: column;align-items: center;height: calc(100% - 24px);overflow-y: hidden;" id="mainTable2">
                <div style="width: 100%;height: 12%;display: flex;color: #fff;justify-content: space-around;" v-for="(item, index) in data2" :key="index">
                  <div style="width: 33.3%;" class="small-table-cell2">{{item.item}}</div>
                  <div style="width: 33.3%;" class="small-table-cell2">{{item.op}}</div>
                  <div style="width: 33.3%;" class="small-table-cell2">{{item.hm}}</div>

                </div>
                <div class="media-font-with-scale" style="color: #FFFFfF;width: 80%;height: 16%">
                  收费范围信息：<b>10-200元/次</b>，<br>根据服务距离、时间、占用人员资源情况
                </div>
              </div>

            </div>

          </div>
        </div>
        <div style=" height: calc((100% - 20px) * 0.38);margin-top: 10px;position: relative">
          <!--    学时趋势    -->
          <div class="title">服务机构与服务对象绑定信息</div>
          <border style="width: 100%;height: calc(100% - 40px);position: absolute;"/>
          <div style="height: 83%;position: relative;z-index: 100;overflow: hidden;padding-top: 18px">
<!--            <div class="list-child"-->
<!--                 v-for="(item, index) in hlData" :key="index">-->
<!--              <div>{{item.name}}</div>-->
            <bar1></bar1>
            </div>
          </div>
<!--          <div class="echart-wrap">-->
<!--            <school-trends-line/>-->
<!--          </div>-->
        </div>
      </div>

        <Modal
            v-model="modal1" class="anKangBIOneModal"
            title="绑定服务老人情况"
            id="myModal1"  draggable scrollable :mask="false" :styles="modalStyles" :footer-hide="true"
        >
          <Table :columns="modalTableColumns" :data="modalTableData" size="small" :max-height="300"></Table>
        </Modal>
        <Modal
            v-model="modal2" class="anKangBIOneModal"
            title="护理员上工情况"
            id="myModal2"  draggable scrollable :mask="false" :styles="modalStyles" :footer-hide="true"
        >
          <Table :columns="modalTableColumns2" :data="modalTableData2" size="small" :max-height="300"></Table>
        </Modal>
    </div>


</template>

<script>
  import period from './period'
  import ageDistributionRadar from "@/view/anKangBIOne/ageDistributionRadar";
  import degreeDistributionBar from "@/view/anKangBIOne/degreeDistributionBar";
  import SchoolTrendsLine from "@/view/anKangBIOne/SchoolTrendsLine";
  import map_baidu from "@/view/anKangBIOne/map_baidu";
  import testTable from "@/view/anKangBIOne/trainingSituationTable";
  import border from "../../components/border.vue"
  import orderManStructure from './orderManStructure'
  import orderManStructure2 from './orderManStructure2'
  import orderManStructure3 from './orderManStructure3'
  import fwryZZT from './fwryZZT'
  import fwryZZT2 from './fwryZZT2'
  import bar1 from './bar1'
  import random_str from "view-design/src/utils/random_str";
  import { getOneRightOneListPage, getOneLiftThreeList, getOneRightTwoList,getOnefuryList } from './anKangBIOne';

  export default {
    name: "anKangBIOne",
    components: {
      border,
      period,
      ageDistributionRadar,
      degreeDistributionBar,
      SchoolTrendsLine,
      testTable,
      map_baidu,
      fwryZZT,fwryZZT2,
      orderManStructure,orderManStructure2,orderManStructure3,bar1
    },
    data() {
      return {
        modal1: false,
        modal2: false,
        value: 0,
        timer: '0',//计时器
        timer1: '0',//
        pwidth: 0,//公告文本的宽度
        animateUp: false,
        windowWidth: document.documentElement.clientWidth,// 设备屏幕的宽度
        height: window.innerHeight + 'px',
        modalStyles: {
          backgroundColor: 'rgba(0,0,0,.5)'
        },
        modalTableData: [],
        modalTableData2: [],
        modalTableColumns: [
          {title: '姓名',key: 'name'},
          {title: '性别',key: 'sex'},
          {title: '年龄',key: 'age'},
          {title: '绑定时间',key: 'date'}
        ],
        modalTableColumns2: [
          {title: '姓名',key: 'name'},
          {title: '性别',key: 'sex'},
          {title: '年龄',key: 'age'},
          {title: '上工情况',key: 'date'}
        ],
        yuanData: [],
        hlData: [],
        laoRenJieGouXinXiFlag: 0,
        fuWuRenYuanJieGouFlag: 0,
        quXianFlag: 0,
        columns1: [
          {title: '区县',key: 'area',align: 'center',},
          {title: '养老院',key: 'yly',align: 'center',},
          {title: '日间照料',key: 'rjzl',align: 'center',},
          {title: '驿站',key: 'yizhan',align: 'center',},
          {title: '护理站',key: 'hlz',align: 'center',},
          {title: '网格员',key: 'wgy',align: 'center',},
          {title: '绑定人数',key: 'bdrs',align: 'center',},
        ],
        data1: [],
        data2: [],

      }
    },
    watch: {
      value(newValue, oldValue) {
        let allWidth = parseInt(this.windowWidth) + parseInt(this.pwidth[0]);
        if (newValue <= -allWidth) {
          this.$refs.cmdlist.style.marginLeft = this.windowWidth + "px";
          this.value = 0;
        }
      },
    },
    methods: {
      getFun(){
        //政府信息公示窗口
        getOneRightOneListPage().then((res) => {
          this.yuanData = res;
        });
        //区县服务结构信息
        getOneLiftThreeList().then((res) => {
          this.data1 = res;
        });
        //服务项目收费信息
        getOneRightTwoList().then((res) => {
          this.data2 = res;
        });

      },
      showModal1(n) {
        let t = n.toString();
        let num = t.substring(1,t.length);
        getOnefuryList(num).then(res=>{
          this.modalTableData = res;
        })
        // this.modalTableData = this['modalTableData' + n]
        this.modal1 = true;
      },
      showModal2(n) {
        let t = n.toString();
        let num = t.substring(1,t.length);
        getOnefuryList(num).then(res=>{
          this.modalTableData2 = res;
        })
        // this.modalTableData2 = this['modalTableData' + n];
        this.modal2 = true;
      },
      changeLaoRenJieGouXinXiFlag(e) {
        this.laoRenJieGouXinXiFlag = e;
      },
      changeFuWuRenYuanJieGouFlag(e) {
        this.fuWuRenYuanJieGouFlag = e;
      },
      changeQuXianFlag(e) {
        this.quXianFlag = e;
      },
      clickCommend(e) {
        let _this = this;
        this.$nextTick(() => {
          this.value -= 1;
          this.$refs.cmdlist.style.marginLeft = _this.windowWidth + this.value + "px";
        });
      },
      menter() {
        clearInterval(this.timer);
      },
      mleave() {
        this.timer = setInterval(this.clickCommend, 0);
      },
      scrollAnimate() {
        this.animateUp = true
        setTimeout(() => {
          this.yuanData.push(this.yuanData[0])
          this.yuanData.shift()
          this.data1.push(this.data1[0])
          this.data1.shift()
          this.animateUp = false
        }, 500)
      }
    },
    destroyed() {
      clearInterval(this.timer);
    },
    mounted() {
      this.getFun();
      this.timer = setInterval(this.scrollAnimate, 3500);

    }
  }
</script>

<style scoped lang="scss">
  .head {
    background: radial-gradient(ellipse, #00102E 0, #000B27 100%);
    height: 100%;
    overflow-y: hidden;

  }

  .bt {
    background-image: url("../../static/imgs/标题.png");
    background-size: 100% 45px;
    background-repeat: no-repeat;
    background-position: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;

  }

  .bt1 {
    color: rgb(0, 255, 255);
    font-size: 27px;

  }

  .box {
    width: calc(100% - 20px);
    border: 1px solid #2163CA;
    padding-left: 55px;
    padding-right: 20px;
    height: 45px;
    margin: auto 10px auto auto;
    position: relative;
    box-shadow: inset 0 0 20px 1px #2163CA;

    &::before {
      position: absolute;
      left: 10px;
      top: 10px;
      content: " ";
      width: 25px;
      height: 25px;
      background-image: url("../../static/imgs/lb.png");
      background-size: 100%;
      background-repeat: no-repeat;
    }

    /*公告内容*/
    .marquee-box {
      overflow: auto;

      .pWidth {
        line-height: 45px;
        color: rgb(0, 255, 255);
        display: block;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        font-family: 微软雅黑, serif;
        fontSize: 14px;
      }
    }
  }

  .middle {
    display: flex;
    width: 100%;
    padding: 0 10px;
    height: calc(100% - 75px);
    /*margin-top: 10px;*/

    .m-left {
      width: calc((100% - 20px) * 0.19);
      height: calc(100% - 74px);
      /*display: flex;*/
      /*flex-direction: column;*/
      position: absolute;
      z-index: 10;
      left: 20px
    }

    .m-middle {
      width: calc((100% - 20px));
      height: 100%;
      /*display: flex;*/
      /*flex-direction: column;*/
      margin-left: 20px;
      margin-right: 20px;
    }

    .m-right {
      width: calc((100% - 20px) * 0.19);
      height: calc(100% - 74px);
      /*display: flex;*/
      /*flex-direction: column;*/
      position: absolute;
      z-index: 10;
      right: 20px;
    }
  }

  .echart-wrap {
    position: absolute;
    top: 50px;
    z-index: 999;
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: center
  }


  .title {
    color: white;
    background-color: rgb(0, 26, 77);
    text-align: center;
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  ::-webkit-scrollbar {
    width: 0 !important;

  }

  ::-webkit-scrollbar {
    width: 0 !important;
    height: 0;

  }

  /*招生人次*/
  .admissionsPeople {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;

  }

  .admissionsPeople1 {
    width: 100%;
    height: calc(100% - 40px);
    background-color: rgb(0, 20, 61);
    position: relative;
  }

  /*学员合格率*/
  .StudentPassRate {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;

  }

  /*学员合格率*/
  .StudentPassRate1 {
    width: 100%;
    height: 100%;

    position: relative;
  }

  .StudentPassRate2 {
    position: absolute;
    z-index: 999;
    bottom: 0;
    left: 0;

  }

  /*招生，报考，结业趋势*/
  .tendency {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;

  }

  .tendency1 {
    width: 100%;
    height: 100%;
    position: relative;

  }

  .tendency2 {
    width: 94%;
    height: 90%;

    position: absolute;
    bottom: 0;

    left: 0;

  }

  /*教师学员年龄分布*/
  .ageDistribution {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;

  }

  .ageDistribution1 {
    width: 100%;
    height: 100%;

    position: relative;


  }

  .ageDistribution2 {
    width: 92%;
    height: 92%;

    position: absolute;
    bottom: 0;

    left: 2%;

  }

  /*教师学员学历分布*/
  .degreeDistribution {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;

  }

  .degreeDistribution1 {
    width: 100%;
    height: 100%;

    position: relative;

  }

  .degreeDistribution2 {
    width: 92%;
    height: 92%;

    position: absolute;
    bottom: 0;

    left: 2%;

  }

  /*学时趋势*/
  .SchoolTrends {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;

  }

  .SchoolTrends1 {
    width: 100%;
    height: 100%;

    position: relative;


  }

  .SchoolTrends2 {
    width: 92%;
    height: 88%;

    position: absolute;
    bottom: 5%;

    left: 2%;

  }

  /*机构*/
  .org {
    width: 100%;
    background-color: rgb(0, 18, 53);
    height: calc((100%));
    position: relative;

    /*&::before {*/
    /*  content: " ";*/
    /*  position: absolute;*/
    /*  top: 0;*/
    /*  bottom: 0;*/
    /*  left: 20%;*/
    /*  right: 0;*/
    /*  margin: auto;*/
    /*  width: 100%;*/
    /*  height: 100%;*/
    /*}*/

    /*.hint {*/
    /*  width: 130px;*/
    /*  height: 50px;*/
    /*  position: relative;*/
    /*  top: 11%;*/
    /*  left: 62%;*/
    /*}*/

    /*.hint1 {*/
    /*  width: 130px;*/
    /*  height: 50px;*/
    /*  position: absolute;*/
    /*  color: rgb(0, 255, 255);*/
    /*  top: 20%;*/
    /*  left: 25%;*/
    /*}*/

    /*.desc-wrap {*/
    /*  position: absolute;*/
    /*  bottom: 15px;*/
    /*  left: 0;*/
    /*  width: 100%;*/
    /*  padding: 0 10px;*/
    /*  height: 20%;*/
    /*  display: flex;*/
    /*  justify-content: space-around;*/

    /*}*/

    /*.desc {*/
    /*  background-image: url("../../static/img/content1.png");*/
    /*  background-size: 100%;*/
    /*  background-repeat: no-repeat;*/
    /*  padding: 10px;*/
    /*}*/

  }


  /*招生人次环比*/
  .AttendanceMonth {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 6%;

  }

  /*开班数量环比*/
  .classesSequential {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 29%;

  }

  /*报考人次环比*/
  .MonthlyApplicants {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 53%;

  }

  /*人均学时环比*/
  .PerCapitaClassHours {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 77%;

  }

  .column {
    width: 100%;
    height: 100%;

    position: relative;


  }

  .column1 {
    width: 95%;
    height: 87%;

    position: absolute;
    top: 9%;

    left: 2%;

  }

  .AttendanceMonth1 {

    position: absolute;
    top: 10%;

    left: 16%;

  }


  /*教师数量*/
  .NumberOfTeachers {
    width: 130px;
    height: 50px;
    position: relative;
    top: 10%;
    left: 15%;

  }

  .NumberOfTeachers1 {
    width: 130px;
    height: 50px;

    position: absolute;
    color: rgb(0, 255, 255);

    top: 20%;

    left: 18%;

  }

  .NumberOfTeachers img {

    transform: rotateY(180deg);

  }

  /*招生人次*/
  .inset {
    width: 9%;
    height: 35px;

    box-shadow: 0px 0px 5px #4185F0 inset;

    color: rgb(0, 255, 255);

    position: absolute;
    top: 20%;

    left: 6%;
    font-size: 23px;
    text-align: center;

  }

  .inset1 {
    width: 9%;
    height: 35px;

    box-shadow: 0px 0px 5px #4185F0 inset;

    color: rgb(0, 255, 255);

    position: absolute;
    top: 20%;

    left: 21%;
    font-size: 23px;
    text-align: center;

  }

  .inset2 {
    width: 9%;
    height: 35px;

    box-shadow: 0px 0px 5px #4185F0 inset;

    color: rgb(0, 255, 255);

    position: absolute;
    top: 20%;

    left: 36%;
    font-size: 23px;
    text-align: center;

  }

  .inset3 {
    width: 9%;
    height: 35px;

    box-shadow: 0px 0px 5px #4185F0 inset;

    color: rgb(0, 255, 255);

    position: absolute;
    top: 20%;

    left: 52%;
    font-size: 23px;
    text-align: center;

  }

  .inset4 {
    width: 9%;
    height: 35px;

    box-shadow: 0px 0px 5px #4185F0 inset;

    color: rgb(0, 255, 255);

    position: absolute;
    top: 20%;

    left: 68%;
    font-size: 23px;
    text-align: center;

  }

  .inset5 {
    width: 9%;
    height: 35px;

    box-shadow: 0px 0px 5px #eaeaea inset;

    color: rgb(0, 255, 255);

    position: absolute;
    top: 20%;

    left: 84%;
    font-size: 23px;
    text-align: center;

  }

  .list-child {
    width:100%;
    margin-bottom: 12px;
    font-size: 14px;
    color: white;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    div{
      flex: 1;
    }
  }

  .marquee-wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;

    .marquee-list {
      li {
        width: 100%;
        height: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0 20px;
        list-style: none;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 400;
      }
    }
    .animate-up {
      transition: all 0.5s ease-in-out;
      transform: translateY(-40px);
    }
  }

  .right-top-btn {
    width: 24px;height: 24px;border-radius: 5px;
    background-color: #152487;display: inline-block;
    font-size: 16px;line-height: 24px;border: 1px solid #ffffff;
    position: absolute;top: 2.5px;cursor: pointer
  }

  .right-top-btn-big {
    width: 48px!important;
  }

  .active-btn-bgcolor {
    background-color: #3181ff!important;
  }

  .animate-up-small-table {
    transition: all 0.5s ease-in-out;
    @media screen and (max-width:1366px){
      transform: translateY(-24px);
    }
    @media screen and (min-width:1366px){
      transform: translateY(-24px);
    }
    @media screen and (min-width:1440px){
      transform: translateY(-27px);
    }
    @media screen and (min-width:1919px){
      transform: translateY(-30px);
    }
  }
  .small-table-cell {
    text-align: center;
    white-space: nowrap;
    @media screen and (max-width:1366px){
      height: 24px;line-height: 24px;
      transform: scale(0.8);
    }
    @media screen and (min-width:1366px){
      height: 24px;line-height: 24px;
      transform: scale(0.8);
    }
    @media screen and (min-width:1440px){
      height: 27px;line-height: 27px;
      transform: scale(0.9);
    }
    @media screen and (min-width:1919px){
      height: 30px;line-height: 30px;
      transform: scale(1.0);
    }
  }
  .small-table-cell2 {
    text-align: center;
    white-space: nowrap;
    @media screen and (max-width:1366px){
      height: 14px;line-height: 14px;
      transform: scale(0.8);
    }
    @media screen and (min-width:1366px){
      height: 14px;line-height: 14px;
      transform: scale(0.8);
    }
    @media screen and (min-width:1440px){
      height: 22px;line-height: 22px;
      transform: scale(0.9);
    }
    @media screen and (min-width:1919px){
      height: 25px;line-height: 25px;
      transform: scale(1.0);
    }
  }


  .media-font {
    @media screen and (max-width:1366px){
      font-size: 12px;
    }
    @media screen and (min-width:1366px){
      font-size: 12px;
    }
    @media screen and (min-width:1440px){
      font-size: 12px;
    }
    @media screen and (min-width:1919px){
      font-size: 14px;
    }
  }

  .media-font-with-scale {
    @media screen and (max-width:1366px){
      transform: scale(0.8);
      font-size: 12px;
    }
    @media screen and (min-width:1366px){
      transform: scale(0.8);
      font-size: 12px;
    }
    @media screen and (min-width:1440px){
      transform: scale(0.9);
      font-size: 12px;
    }
    @media screen and (min-width:1919px){
      transform: scale(1.0);
      font-size: 14px;
    }
  }

  .media-scale {
    @media screen and (max-width:1366px){
      transform: scale(0.8);
    }
    @media screen and (min-width:1366px){
      transform: scale(0.8);
    }
    @media screen and (min-width:1440px){
      transform: scale(0.9);
    }
    @media screen and (min-width:1919px){
      transform: scale(1.0);
    }
  }
</style>
<style lang="scss">
.special {
  .ivu-table{
    color: #fff;
    background-color: rgba(0,0,0,0)!important;
    font-size: 10px!important;
    overflow-y: auto!important;
  }
  .ivu-table-column-center{
    border-bottom: 1px solid #fff!important;
  }
  .ivu-table:before, .ivu-table:after{
    background-color: rgba(0,0,0,0)!important;
  }
  .ivu-table td, .ivu-table th{
    background-color: rgba(0,0,0,0)!important;
  }
  .ivu-table-header th{
    background-color: rgba(0,0,0,0)!important;
  }
  .ivu-table-cell{
    padding: 0 3px 0!important;
  }
}

.anKangBIOneModal {
  .ivu-modal-content {
    background: linear-gradient(rgba(23, 34, 60, 0.88), rgba(33, 99, 202, 0.88));
    //filter: blur(5px);
  }
  .ivu-modal-header {
    color: #ffffff;
  }
  .ivu-modal-header-inner {
    color: #ffffff;
  }
  .ivu-table td {
    background-color: transparent;
    border-bottom: 0;
  }
  .ivu-table-cell {
    color: #ffffff;
  }

  /* 设置滚动条的样式 */
  ::-webkit-scrollbar {
    width: 12px;
  }
  /* 滚动槽 */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
  }

}
</style>

