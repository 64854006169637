<template>
  <div class="echart" id="bar1" :style="{ width: '100%', height: '100%' }"></div>
</template>

<script>
import echarts from 'echarts';
import {getOneRightThreeList} from "./anKangBIOne";
export default {
  name: 'bar1',
  data() {
    return {
      labelOption: '',
      app: {},
      data:[],
      dataOrg:[],
      dataNum:[],
    };
  },
  methods: {
    getFun(){
      //服务机构与服务对象绑定信息
      getOneRightThreeList().then((res) => {
        this.data = res;
        if(this.data.length>0){
          this.dataOrg = [];
          this.dataNum = [];
          let _ = this;
          this.data.forEach(function(value) {
            _.dataOrg.push(value.org);
            _.dataNum.push(value.number);

          });
        }
        this.initChart()
      });
    },
    initChart() {
      // prettier-ignore
      let dataAxis =  this.dataOrg;
// prettier-ignore
      let data = this.dataNum;
      let yMax = 500;
      let dataShadow = [];
      for (let i = 0; i < data.length; i++) {
        dataShadow.push(yMax);
      }
      let option = {
        // title: {
        //   text: '',
        //   subtext: ''
        // },
        tooltip: {

          show: true
        },
        grid: {
          top: '3%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          data: dataAxis,
          axisLabel: {
            inside: false,
            color: '#fff',
            rotate: 30
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },

          z: 10
        },
        yAxis: {
          color: '#fff',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            color: '#FFFFFF',
            formatter: '{value} 人'
          },
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        series: [
          {
            type: 'bar',
            showBackground: false,

            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' }
              ])
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#83bff6' },
                  { offset: 0.7, color: '#83bff6' },
                  { offset: 1, color: '#83bff6' }
                ])
              }
            },
            data: data
          }
        ]
      };
// Enable data zoom when user click bar.
      const zoomSize = 6;

      let getchart = echarts.init(document.getElementById('bar1'));
      getchart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener('resize', () => {
        getchart.resize();
      });

      getchart.on('click', function (params) {
        console.log(dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)]);
        getchart.dispatchAction({
          type: 'dataZoom',
          startValue: dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
          endValue:
              dataAxis[Math.min(params.dataIndex + zoomSize / 2, data.length - 1)]
        });
      });
    },

  },
  mounted() {
    this.getFun();
    // this.initChart();
  },
};
</script>

<style scoped></style>
