<template>
  <div class="echart" id="echart-periodPie" :style="{ float: 'left', width: '100%', height: '100%' }"></div>
</template>

<script>
  import echarts from 'echarts';

  export default {
    name: 'periodPie',
    data() {
      return {
        data: [{
          "name": "已绑定",
          "value": 456
        }, {
          "name": "未绑定",
          "value": 231
        }]
      }
    },
    methods: {
      initChart() {
        let getchart = echarts.init(document.getElementById('echart-periodPie'));
        var periodPieOption = {
          tooltip: {
            trigger: 'axis'
          },
          title: {
            // text: '占比情况',
            textStyle: {
              color: '#f2f2f2',
              fontSize: 11,
            },
            subtextStyle: {
              fontSize: 30,
              color: ['#ff9d19']
            },
            x: '40%',
            y: '30%',
          },
          legend: {
            top:-40,
            data: ['已绑定', '未绑定'],
            orient: 'horizontal',
            itemGap: 25,
            padding: [50, 15],
            textStyle: {
              fontSize: 12,
              color: '#fff'
            },
          },
          grid: {
            top: '50%',
            left: 10,
            right: 10,
            bottom: 10,
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['2012', '2013', '2014', '2015', '2016','2017','2019','2020','2021'],
            axisLabel: {
              textStyle: {
                color: '#7B90CC'
              },
              formatter: function (params) {
                return params.split(' ')[0]
              }
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false
            },
          },
          yAxis: {
            name: '',
            type: 'value',
            axisLabel: {
              formatter: '{value}',
              textStyle: {
                color: '#7B90CC'
              }
            },

            splitLine: {
              show: false,
              lineStyle: {
                color: 'rgb(23,255,243,0.3)'
              }
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: 'rgba(255,0,0,0.0)'
              }
            }
          },
          series: [
            {
              name: '已绑定',
              type: 'line',
              color: 'rgba(119,61,190)',
              smooth: true,
              showSymbol: false,
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(119,61,190' + ', 1)'
                  }, {
                    offset: 0.8,
                    color: 'rgba(119,61,190' + ', 0.02)'
                  }], false),
                  shadowColor: 'rgba(0, 0, 0, 0.1)',
                  shadowBlur: 10
                }
              },
              symbol: 'circle',
              symbolSize: 5,
              data: [120,30,70,20,180,60,160,140,30,80]
            },
            {
              name: '未绑定',
              type: 'line',
              color: 'rgba(65,133,240)',
              smooth: true,
              showSymbol: false,
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(65,133,240' + ', 1)'
                  }, {
                    offset: 0.8,
                    color: 'rgba(65,133,240' + ', 0.02)'
                  }], false),
                  shadowColor: 'rgba(0, 0, 0, 0.1)',
                  shadowBlur: 10
                }
              },
              symbol: 'circle',
              symbolSize: 5,
              data: [180,120,170,20,150,60,70,190,52,30]
            },
            {
              color: ['rgba(23, 255, 243', 'rgba(119,61,190', 'rgba(65,133,240'],
              tooltip: {
                trigger: 'item',
                formatter: '{b} ({d}%)'
              },
              radius: ['25%', '35%'],
              center: ['50%', '35%'],
              type: 'pie',
              label: {
                position: 'inner',
                formatter: '{b}：{c}亿元',
                show: false
              },
              labelLine: {
                normal: {
                  show: false,
                  length: 30,
                  length2: 55
                },
                emphasis: {
                  show: false
                }
              },
              data: this.data,

            },]
        };

        getchart.setOption(periodPieOption);
        //随着屏幕大小调节图表
        window.addEventListener('resize', () => {
          getchart.resize();
        });
      },
    },
    mounted() {
      this.initChart();
    },
  };
</script>

<style scoped></style>
