<template>
  <div class="wrap">
    <div class="line"></div>
    <div class="line2"></div>
    <div class="block"></div>
  </div>

</template>
<!--边框-->
<script>
  export default {
    name: "border"
  }
</script>

<style scoped>
  .wrap{
    width:400px;
    height:200px;
    position:relative;
    background-size: 100% 100%;
    background: linear-gradient(rgba(23, 34, 60, 0.3), rgba(33, 99, 202, 0.7)) no-repeat;
    clip-path: polygon(15px 0, calc(100% - 15px) 0, 100% 15px,
    100% calc(100% - 15px), calc(100% - 15px) 100%,
    15px 100%, 0 calc(100% - 15px), 0 15px);

  }

  .line{
    width:100%;
    height:40px;
    background-color:#2163CA;
    clip-path: polygon(0% 0%, calc(85% - 130px) 0%, calc(85% - 127px)  7px, calc(85% - 3px) 7px, 85% 0%, 100% 0%,
    100% 100%, calc(100% - 2px) 100%, calc(100% - 2px) 15px, calc(100% - 15px) 2px, 85%  2px,
    calc(85% - 3px) 9px, calc(85% - 130px) 9px, calc(85% - 133px) 2px, 15px 2px, 2px 15px, 2px 100%, 0 100%);

  }

  .line2{
    position: absolute;
    right:0;
    bottom:0;
    width:100%;
    height:40px;
    background-color:#2163CA;
    clip-path: polygon(0% 0%, calc(90% - 100px) 0%, calc(90% - 97px)  7px, calc(90% - 3px) 7px, 90% 0%, 100% 0%,
    100% 100%, calc(100% - 2px) 100%, calc(100% - 2px) 15px, calc(100% - 15px) 2px, 90%  2px,
    calc(90% - 3px) 9px, calc(90% - 100px) 9px, calc(90% - 103px) 2px, 15px 2px, 2px 15px, 2px 100%, 0 100%);
    transform:scaleY(-1);
  }



  .wrap:before{
    content:" ";
    width:133px;
    height:10px;
    background-color:#4CCCFF;
    position:absolute;
    top:-4px;
    right:15%;
    clip-path: polygon(1px 0, 100%  0, 100% 9px,
    100% calc(100% - 9px), calc(100% - 9px) 100%,
    9px 100%, 0 calc(100% - 9px), 0 9px);
  }

  .block{
    position:absolute;
    right:calc(10% + 5px) ;
    bottom:0;
    width:93px;
    height:6px;
    background: repeating-linear-gradient(75deg, #4CCCFF, #4CCCFF 20px, rgba(255,255,255,0) 0, rgba(255,255,255,0) 23px);
    transform: skewX(-35deg);
  }

</style>
