<template>
  <div class="echart" id="echart-degreeDistributionBar" :style="{ float: 'left', width: '100%', height: '100%' }"></div>
</template>

<script>
import echarts from 'echarts';
export default {
  name: 'degreeDistributionBar',
  data() {
    return {

    };
  },
  methods: {
    initChart() {
      let getchart = echarts.init(document.getElementById('echart-degreeDistributionBar'));
      var degreeDistributionBarOption = {

        // backgroundColor: '#00265f',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          top: '15%',
          right: '3%',
          left: '8%',
          bottom: '13%'
        },
        xAxis: [{
          type: 'category',
          data: ['晨间护理', '晚间护理', '卧位护理', '排泄护理', '床上温水擦浴', '其他护理'],
          axisLine: {
            lineStyle: {
              color: '#FFFFFF'
            }
          },
          axisLabel: {
            margin: 10,
            color: '#e2e9ff',
            textStyle: {
              fontSize: 12
            },
          },
          axisTick: {
            show: false
          }
        }],
        yAxis: [{
          axisLabel: {
            formatter: '{value}',
            color: '#e2e9ff',
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0,186,255,.6)'
            }
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0.12)'
            }
          }
        }],
        series: [{
          type: 'bar',
          data: [80, 80, 97, 53, 95, 26],
          barWidth: '28px',
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(0,244,255,1)' // 0% 处的颜色
              }, {
                offset: 1,
                color: 'rgba(0,77,167,1)' // 100% 处的颜色
              }], false),
              shadowColor: 'rgba(0,160,221,1)',
              shadowBlur: 4,
            }
          },
          label: {
            normal: {
              show: true,
              lineHeight: 30,
              formatter: '{c}',
              position: 'top',
              textStyle: {
                color: '#00D6F9',
                fontSize: 14
              }

            }
          }
        }]
      };

      getchart.setOption(degreeDistributionBarOption);
      //随着屏幕大小调节图表
      window.addEventListener('resize', () => {
        getchart.resize();
      });
    },
  },
  mounted() {
    this.initChart();
  },
};
</script>

<style scoped></style>
