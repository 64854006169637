<template>
  <div class="echart" id="echart-orderManStructure3" :style="{ float: 'left', width: '100%', height: '100%' }"></div>
</template>

<script>
import echarts from 'echarts';
import {getOneLeftOneThreeList} from "./anKangBIOne";
export default {
  name: 'orderManStructure3',
  data() {
    return {
      list:[],
      backgroundColor: '#101736',
      title :{
        text: '',
        textStyle: {
          color: '#fff',
          fontSize: 16,
        },
        padding: 0,
        top: 35,
        left: 'center',
      },
      legend : {
        //data，就是取得每个series里面的name属性。
        orient: 'vertical',
        icon: 'circle', //图例形状
        padding: 0,
        bottom: 'center',
        right: 40,
        itemWidth: 14, //小圆点宽度
        itemHeight: 12, // 小圆点高度
        itemGap: 15, // 图例每项之间的间隔。[ default: 10 ]横向布局时为水平间隔，纵向布局时为纵向间隔。
        textStyle: {
          fontSize: 12,
          color: '#ffffff',
        },
      },
      sw: {
        g: 15,
        fs: 10,
        ls: 16,
      }
    }
  },
  methods: {
    getFun(){
      //老人结构信息 居
      getOneLeftOneThreeList().then((res) => {
        this.list = res;
        this.initChart();
      });
    },
    initChart() {
      //做环形图的本质是 radius: ['内半径', '外半径'] 内半径!=0
      const title = {
        // text: '网络/安全设备',
        textStyle: {
          color: '#fff',
          fontSize: 16,
        },
        padding: 0,
        top: 35,
        left: 'center',
      };
      const legend = {
        //data，就是取得每个series里面的name属性。
        orient: 'vertical',
        icon: 'circle', //图例形状
        padding: 0,
        bottom: 'left',
        left: '8%',
        top:'8%',
        itemWidth: 12, //小圆点宽度
        itemHeight: 12, // 小圆点高度
        itemGap: this.sw.g, // 图例每项之间的间隔。[ default: 10 ]横向布局时为水平间隔，纵向布局时为纵向间隔。
        textStyle: {
          fontSize: this.sw.fs,
          color: '#ffffff',

        },
      };
      const tooltip = {
        show: false,
        formatter: '{b}:{d}%',
      };
      const color = ['#03acd1', '#04cab7', '#03c781', '#fce348', '#fc2d8a', '#0292fe'];
      let getchart = echarts.init(document.getElementById('echart-orderManStructure3'));
      let _this = this;
      var orderManStructureOption = {
        // backgroundColor,
        color,
        title,
        tooltip,
        legend,

        series: [
          {
            name: '',
            type: 'pie',
            center: ['60%', '50%'], //圆心的位置
            top: '2%', //单单指的饼图距离上面的距离，top越大饼图越小
            left: '0%', //单单指的饼图距离左面的距离，会改变饼图的大小
            radius: ['40%', '90%'], //环形图的本质就在这里 [内半径!=0，外半径] 外半径越大，圆越大
            avoidLabelOverlap: false, //做同心圆用到
            clockwise: true, //顺时针排列
            startAngle: 90, //起始角度 影响不大
            //roseType:"", // 实心圆 不能出现roseType这个属性

            label: {
              show: true, //false不显示饼图上的标签
              position: 'inside', //inside（在饼图上显示）,outside(默认就会出现引导线) center
              formatter: '{b}：\n{d}%' + '({c})',
              color: '#fff'
            },

            itemStyle: {
              //每个扇形的设置
              borderColor: 'rgba(0,0,0,.1)', //扇形边框颜色
              borderWidth: 1, //扇形边框大小 要先给borderColor颜色 设置borderWidth才会有效果

            },
            data: this.list.sort((a, b) => a.value -  b.value ), //数组从大到小排序

            emphasis: {
              scale: true,
              scaleSize: 10,
              //同心圆单独加上的
              label: {
                show: true,
                fontSize: 24,
                fontWeight: 'bold'
              },
              //启用鼠标放上去放大效果，这个挺好的
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };

      getchart.setOption(orderManStructureOption);
      //随着屏幕大小调节图表
      window.addEventListener('resize', () => {
        getchart.resize();
      });
    },

  },
  mounted() {
    let sw = window.screen.width;
    if(sw <= 1366) {
      this.sw.g = 5;
      this.sw.fs = 10;
      this.sw.ls = 16;
    } else if( 1366< sw <= 1600) {
      this.sw.g = 8;
      this.sw.fs = 10;
      this.sw.ls = 20;
    }
    else {
      this.sw.g = 15;
      this.sw.fs = 12;
      this.sw.ls = 24;
    }
    this.getFun();
  },
};
</script>

<style scoped></style>
