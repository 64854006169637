import server from '../../util/server';
import context from "../../util/context";

/**
 * 政府信息公示窗口
 * @returns {*}
 */
export function getOneRightOneListPage() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneRightOneListPage',
    });
}

/**
 * 区县服务结构信息
 * @returns {Promise<unknown>}
 */
export function getOneLiftThreeList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneLiftThreeList',
    });
}

/**
 * 服务项目收费信息
 * @returns {Promise<unknown>}
 */
export function getOneRightTwoList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneRightTwoList',
    });
}

/**
 * 服务机构与服务对象绑定信息
 * @returns {Promise<unknown>}
 */
export function getOneRightThreeList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneRightThreeList',
    });
}

/**
 * 服务人员结构信息 人社
 * @returns {Promise<unknown>}
 */
export function getOneLeftTwoOneList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneLeftTwoOneList',
    });
}

/**
 * 服务人员结构信息 机构
 * @returns {Promise<unknown>}
 */
export function getOneLeftTwoTwoList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneLeftTwoTwoList',
    });
}

/**
 * 老人结构信息 龄
 * @returns {Promise<unknown>}
 */
export function getOneLeftOneOneList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneLeftOneOneList',
    });
}
/**
 * 老人结构信息 能
 * @returns {Promise<unknown>}
 */
export function getOneLeftOneTwoList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneLeftOneTwoList',
    });
}
/**
 * 老人结构信息 居
 * @returns {Promise<unknown>}
 */
export function getOneLeftOneThreeList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneLeftOneThreeList',
    });
}

/**
 * 查询机构信息方法
 * @returns {Promise<unknown>}
 */
export function getOneDtList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneDtList',
    });
}

/**
 *
 * @returns {Promise | Promise<unknown>}
 */
export function getOnefuryList(number) {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOnefuryList',
        params: {
            number: number,
        },
    });
}

/**
 *
 * @returns {Promise | Promise<unknown>}
 */
export function getOrgList() {
    return server.get({
        url: context.baseUrl + '/base/orderConnection/getOrgList',
    });
}

