import { render, staticRenderFns } from "./orderManStructure2.vue?vue&type=template&id=11a15e07&scoped=true&"
import script from "./orderManStructure2.vue?vue&type=script&lang=js&"
export * from "./orderManStructure2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11a15e07",
  null
  
)

export default component.exports